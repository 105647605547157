import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getBlogPostsAsync, getBlogPostsByTagAsync } from '../Shared/Utilities/BlogService';
import './BlogSideBar.scss';

const BlogSidePosts = () => {

    const [blogPosts, setBlogPosts] = useState([{ tags: [] }]);

    useEffect(() => {
        const getPosts = async () => {
            let response;
            response = await getBlogPostsAsync(1);
            setBlogPosts(response.blogPosts);
        }

        getPosts();
    }, []);

    return (
        <>
            <div className="side-bar float-right">
                <div className="row">
                    <h3>Spotlight</h3>
                </div>

                {blogPosts.map((bp, i) =>
                    <Link key={i} to={`/blog/${bp.urlSlug}`}>
                        <div className="row pb-4">
                            <div className="side-post d-flex flex-row">
                                <div className="thumbnail ">
                                    <img className="" src={bp.imageUrl} alt={bp.title} />
                                </div>
                                <div className="">
                                    {/* First tag of blog post to go here  */}
                                    <span className="tag">{bp.tags.length > 0 ? bp.tags[0].value : "No tags"}</span>
                                    <p>{bp.title}</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                )}
            </div>
        </>
    );
};

export default BlogSidePosts;