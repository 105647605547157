import React from 'react';
import Marquee from "react-fast-marquee";
import E2M from '../../../../Assets/Images/OurPartners/e2m.jpg';
import Innovate from '../../../../Assets/Images/OurPartners/innovateher.png';
import BLS from '../../../../Assets/Images/OurPartners/BLS.png';
import Abe from '../../../../Assets/Images/OurPartners/abeuk.jpg';
import Peel from '../../../../Assets/Images/OurPartners/Peel Logo.png';
import IUCA from '../../../../Assets/Images/OurPartners/IUCA Logo.jpeg';
import WhatIf from '../../../../Assets/Images/OurPartners/WhatIf.png';
import Grey4Gold from '../../../../Assets/Images/OurPartners/grey4gold.png';
import Thames from '../../../../Assets/Images/OurPartners/Thames.png';
import TerraCarter from '../../../../Assets/Images/OurPartners/TC Logo.jpg';
import SustainSocial from '../../../../Assets/Images/OurPartners/social.jpg';
import BetterBusinessAct from '../../../../Assets/Images/OurPartners/betterbusinessact.png';
import './ScrollingBanner.scss';
import { FadeInOnScroll } from '../../Utilities/FadeInOnScroll';
import UN from '../../../../Assets/Images/OurPartners/UN.jpeg';

const ScrollingBanner = ({ lineText, title, text, align, bgColor }) => {
    const [ref] = FadeInOnScroll();

    const logos = [
        {
            Logo: E2M
        },
        {
            Logo: UN
        },
        {
            Logo: Innovate
        },
        {
            Logo: Grey4Gold
        },
        {
            Logo: Thames
        },
        {
            Logo: BetterBusinessAct
        },
        {
            Logo: TerraCarter
        },
        {
            Logo: Peel
        },
        {
            Logo: SustainSocial
        },
        {
            Logo: IUCA
        },
        {
            Logo: WhatIf
        },
        {
            Logo: E2M
        },
        {
            Logo: UN
        },
        {
            Logo: Innovate
        },
        {
            Logo: Grey4Gold
        },
        {
            Logo: Thames
        },
    ];

    return (
        <>
            <Marquee
                pauseOnHover={true}
                speed="35"
                gradient={true}
                gradientWidth="100"
                marginRight="0"
            >
                <div ref={ref} className="py-3 scroll-container" style={{ backgroundColor: `${bgColor ? bgColor : ""}` }}>
                    <div className="col-12 scroll-banner">
                        {logos.map((l, i) => (
                            <img key={i} className="img-fluid" src={l.Logo} alt="" />
                        ))}
                    </div>
                </div>

            </Marquee>
        </>

    );
};

export default ScrollingBanner;
