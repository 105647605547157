import React from 'react';
import './MobileMenu.scss';
import Logo from '../../Assets/Images/TheSustanabilityAcademy-LandscapeWhite.png';
import { Link } from 'react-router-dom';
import { Fade as Hamburger } from 'hamburger-react'

const MobileMenu = (props) => {
    return (
        <div className={`mobMenu ${props.mobMenu ? "open" : "hidden"}`}>
            <div className="row no-gutters">
                <Hamburger color="white" rounded toggled={true} toggle={() => props.toggleMenu()} />
                <div className="logo pt-5">
                    <img className="img-fluid" width="400px" height="120px" src={Logo} alt="The Sustainability Academy" />
                </div>
            </div>
            <div className="menu-links">
                <ol>
                    <li>
                        <Link to="/" onClick={() => props.toggleMenu()}>Home</Link>
                    </li>
                    <li>
                        <Link to="/about" onClick={() => props.toggleMenu()}>About</Link>
                    </li>
                    <li>
                        <Link to="/our-courses" onClick={() => props.toggleMenu()}>Our Courses</Link>
                    </li>
                    <li>
                        <Link to="/kids-sdgs" onClick={() => props.toggleMenu()}>KidsSDGs</Link>
                    </li>
                    <li>
                        <Link to="/our-supporters" onClick={() => props.toggleMenu()}>Our Supporters</Link>
                    </li>
                    <li>
                        <Link to="/contact-us" onClick={() => props.toggleMenu()}>Contact Us</Link>
                    </li>
                    <li>
                        <Link to="/blog" onClick={() => props.toggleMenu()}>Contact Us</Link>
                    </li>
                    <li>
                        <a onClick={() => props.toggleMenu()} href="https://elearning.thesustainabilityacademy.co.uk"><strong>Login</strong></a>
                    </li>
                </ol>
            </div>
        </div>
    );
};

export default MobileMenu;