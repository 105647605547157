import React from 'react';
import LineText from './../LineText/LineText';

const RowText = ({ lineText, title, divClass, children }) => {
    return (
        <div className="row no-gutters">
            <div className={`col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center ${divClass ? divClass : ""}`}>
                <LineText lineText={lineText} center={true} />
                <h2>{title}</h2>
                {children}
            </div>
        </div>
    );
};

export default RowText;