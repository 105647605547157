import React from 'react';
import './CourseTestimonials.scss';
import Carousel from 'react-elastic-carousel';
import TextRow from './../../../Shared/Components/TextRow/TextRow';

const CourseTestimonials = ({ divClass, lineText, title, paragraph, data }) => {

    return (

        <TextRow
            divClass={divClass}
            lineText={lineText}
            title={title}
            paragraph={paragraph}
        >
            <div className="mt-5">
                <Carousel
                    enableAutoPlay={false}
                    pagination={false}
                    itemPadding={[10]}
                >
                    {
                        data.map((t, i) => (
                            <div key={`${i}-testimonial`} className="course-testimonial-container justify-content-center align-items-center" style={{ width: '100%' }}>
                                <div key={i} className="row no-gutters course-testimonials" style={{ width: '100%' }}>
                                    <div className="col-12 text-center ac-content">
                                        <h6>{t.Title}</h6>
                                        <p className="card-text">{t.Testimonial}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </Carousel>
            </div>
        </TextRow>
    );
};

export default CourseTestimonials;