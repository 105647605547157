import React, { useState } from 'react';
import Row from './../../../Shared/Components/Row/Row';
import FsLightbox from 'fslightbox-react';
import LineText from './../../../Shared/Components/LineText/LineText';
import './Video.scss';
import LineIcon from 'react-lineicons';
import { FadeInOnScroll } from '../../Utilities/FadeInOnScroll';

const Video = ({ image, video, lineText, title, paragraph }) => {

    const [toggler, setToggler] = useState(false);
    const [ref] = FadeInOnScroll();

    return (
        <Row divClass="bgGrey video-section py-5" >
            <div ref={ref}>
                <LineText lineText={lineText} />
                <div className="col-12 col-lg-10 offset-lg-1 text mb-4">
                    <h2>{title}</h2>
                    <div className="mt-3">
                        <p>{paragraph}</p>
                    </div>
                </div>
                <div className="video-image">
                    <img src={image} className="img-fluid" alt="sdg video" />
                    <div className="icon-circle" onClick={() => setToggler(!toggler)}>
                        <LineIcon name="play" alt="Watch our video" style={{ color: "white" }} />
                    </div>
                </div>
                <FsLightbox
                    toggler={toggler}
                    sources={[
                        <div className="">
                            <video className="video fadeInDelay animated" style={{ width: "80vw" }}
                                src={video}
                                controls
                                playsInline
                                type='video/mp4'
                                autoPlay
                            />
                        </div>
                    ]}
                />
            </div>
        </Row>
    );
};

export default Video;