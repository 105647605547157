import React from 'react';
import GoalList from './../GoalList/GoalList';

const Programmes = ({ divClass }) => {

    return (
        <>
            <GoalList />
        </>
    );
};

export default Programmes;