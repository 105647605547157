export const TestimonialArray = [
    {
        Testimonial: "Loved them , learnt a lot too. Definitely needed as mandatory training within all organisations"
    },
    {
        Testimonial: "So much information! Very informative and surprising (to me anyway as I knew zero about the SDGs). Love them though, very easy to follow"
    },
    {
        Testimonial: "The modules are so good, and clear instructions all the way through to completion - learnt a lot"
    },
    {
        Testimonial: "All modules so good, so informative – the facts and figures show the urgency for Action As a learner , the Instructions are clear and easy to follow through to completion"
    },
    {
        Testimonial: "The course was very interesting and informative, I enjoyed it a lot!"
    },
    {
        Testimonial: "Really liked SDG2, very interesting and eye opening."
    },
];