import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import Logo from '../../Assets/Images/TSA1.png';
import './Navbar.scss';
import { Fade as Hamburger } from 'hamburger-react'

const Navbar = (props) => {
    return (
        <nav className={`navbar navbar-expand-lg bg-white navbar-light fixed-top shadow-sm`}>
            <div className="container-xxl d-flex justify-content-between">
                <Link className="navbar-brand" to="/">
                    <img alt="The Sustainability Academy Sustainable Development Goals" width="500px" height="" id="brand-logo" className="img-fluid" src={Logo} /></Link>
                <div className="d-lg-none d-flex align-items-center">
                    <Hamburger rounded toggled={false} toggle={() => props.toggleMenu()} />
                </div>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item active">
                            <NavLink exact={true} className="nav-link" to="/">Home</NavLink>
                        </li>
                        <li className="nav-item active">
                            <NavLink className="nav-link" to="/about">About</NavLink>
                        </li>
                        <li className="nav-item active">
                            <NavLink className="nav-link" to="/our-courses">Our Courses</NavLink>
                        </li>
                        <li>
                            <NavLink className="nav-link" to="/kids-sdgs">KidsSDGs</NavLink>
                        </li>
                        <li className="nav-item active">
                            <NavLink className="nav-link" to="/our-supporters">Our Supporters</NavLink>
                        </li>
                        <li className="nav-item active">
                            <NavLink className="nav-link" to="/blog">Blog</NavLink>
                        </li>
                        <li className="nav-item active">
                            <NavLink className="nav-link" to="/contact-us">Contact</NavLink>
                        </li>
                        <li className="nav-item active">
                            <a className="nav-link" href="https://elearning.thesustainabilityacademy.co.uk"><strong>Login</strong></a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;