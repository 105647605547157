export function baseUrl() {
    if (window.location.href.includes('localhost')) {
        return `https://localhost:5001/api/`;
        //return `https://api.${HOST}/api/`
    }
    else if (window.location.href.includes('qa')) {
        return `https://api.elearning.qa.academyone.co.uk/api/`
    }
    else {
        return `https://api.elearning.academyone.co.uk/api/`
    }
}