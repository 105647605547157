
import sdg1 from '../../../../Assets/Images/Training/UNLogos/UN-01.jpg';
import sdg2 from '../../../../Assets/Images/Training/UNLogos/UN-02.jpg';
import sdg3 from '../../../../Assets/Images/Training/UNLogos/UN-03.jpg';
import sdg4 from '../../../../Assets/Images/Training/UNLogos/UN-04.jpg';
import sdg5 from '../../../../Assets/Images/Training/UNLogos/UN-05.jpg';
import sdg6 from '../../../../Assets/Images/Training/UNLogos/UN-06.jpg';
import sdg7 from '../../../../Assets/Images/Training/UNLogos/UN-07.jpg';
import sdg8 from '../../../../Assets/Images/Training/UNLogos/UN-08.jpg';
import sdg9 from '../../../../Assets/Images/Training/UNLogos/UN-09.jpg';
import sdg10 from '../../../../Assets/Images/Training/UNLogos/UN-10.jpg';
import sdg11 from '../../../../Assets/Images/Training/UNLogos/UN-11.jpg';
import sdg12 from '../../../../Assets/Images/Training/UNLogos/UN-12.jpg';
import sdg13 from '../../../../Assets/Images/Training/UNLogos/UN-13.jpg';
import sdg14 from '../../../../Assets/Images/Training/UNLogos/UN-14.jpg';
import sdg15 from '../../../../Assets/Images/Training/UNLogos/UN-15.jpg';
import sdg16 from '../../../../Assets/Images/Training/UNLogos/UN-16.jpg';
import sdg17 from '../../../../Assets/Images/Training/UNLogos/UN-17.jpg';

import videosdg1 from '../../../../Assets/Video/sdgVideos/SDG1.mp4';
import videosdg2 from '../../../../Assets/Video/sdgVideos/SDG2.mp4';
import videosdg3 from '../../../../Assets/Video/sdgVideos/SDG3.mp4';
import videosdg4 from '../../../../Assets/Video/sdgVideos/SDG4.mp4';
import videosdg5 from '../../../../Assets/Video/sdgVideos/SDG5.mp4';
import videosdg6 from '../../../../Assets/Video/sdgVideos/SDG6.mp4';
import videosdg7 from '../../../../Assets/Video/sdgVideos/SDG7.mp4';
import videosdg8 from '../../../../Assets/Video/sdgVideos/SDG8.mp4';
import videosdg9 from '../../../../Assets/Video/sdgVideos/SDG9.mp4';
import videosdg10 from '../../../../Assets/Video/sdgVideos/SDG10.mp4';
import videosdg11 from '../../../../Assets/Video/sdgVideos/SDG11.mp4';
import videosdg12 from '../../../../Assets/Video/sdgVideos/SDG12.mp4';
import videosdg13 from '../../../../Assets/Video/sdgVideos/SDG13.mp4';
import videosdg14 from '../../../../Assets/Video/sdgVideos/SDG14.mp4';
import videosdg15 from '../../../../Assets/Video/sdgVideos/SDG15.mp4';
import videosdg16 from '../../../../Assets/Video/sdgVideos/SDG16.mp4';
import videosdg17 from '../../../../Assets/Video/sdgVideos/SDG17.mp4';

export const sdgItems = [
    {
        name: "SDG1",
        image: sdg1,
        videoLink: videosdg1
    },
    {
        name: "SDG2",
        image: sdg2,
        videoLink: videosdg2
    },
    {
        name: "SDG3",
        image: sdg3,
        videoLink: videosdg3
    },
    {
        name: "SDG4",
        image: sdg4,
        videoLink: videosdg4
    },
    {
        name: "SDG5",
        image: sdg5,
        videoLink: videosdg5
    },
    {
        name: "SDG6",
        image: sdg6,
        videoLink: videosdg6
    },
    {
        name: "SDG7",
        image: sdg7,
        videoLink: videosdg7
    },
    {
        name: "SDG8",
        image: sdg8,
        videoLink: videosdg8
    },
    {
        name: "SDG9",
        image: sdg9,
        videoLink: videosdg9
    },
    {
        name: "SDG10",
        image: sdg10,
        videoLink: videosdg10
    },
    {
        name: "SDG11",
        image: sdg11,
        videoLink: videosdg11
    },
    {
        name: "SDG12",
        image: sdg12,
        videoLink: videosdg12
    },
    {
        name: "SDG13",
        image: sdg13,
        videoLink: videosdg13
    },
    {
        name: "SDG14",
        image: sdg14,
        videoLink: videosdg14
    },
    {
        name: "SDG15",
        image: sdg15,
        videoLink: videosdg15
    },
    {
        name: "SDG16",
        image: sdg16,
        videoLink: videosdg16
    },
    {
        name: "SDG17",
        image: sdg17,
        videoLink: videosdg17
    }
];