import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import './App.scss';
import Layout from './Layout/Layout/Layout';
import { createBrowserHistory } from 'history';
import About from './Pages/About/About';
import BespokeTraining from './Pages/BespokeTraining/BespokeTraining';
import NotFound from './Pages/Shared/NotFound';
import Home from './Pages/Home/Home';
import ScrollTop from './Pages/Shared/Utilities/ScrollTop';
import ContactUs from './Pages/ContactUs/ContactUs';
import TermsAndConditions from './Pages/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import OurSupporters from './Pages/OurSupporters/OurSupporters';
import Schools from './Pages/Schools/Schools';
import { Helmet } from "react-helmet";
import Blog from './Pages/Blog/Blog';
import BlogPost from './Pages/Blog/BlogPost';

const history = createBrowserHistory();

function App() {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>The Sustainability Academy | </title>
        <meta name="description" content="" />
        <meta name="theme-color" content="#E6E6FA" />
        <keywords>Education, Sustainable Development Goals, Sustainability Training, SDG, SDGs</keywords>
        <meta name="robots" content="" />
      </Helmet>

      <Router history={history}>
        <ScrollTop>
          <Layout>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/about" component={About} />
              <Route exact path="/our-courses" component={BespokeTraining} />
              <Route exact path="/kids-sdgs" component={Schools} />
              <Route exact path="/our-supporters" component={OurSupporters} />
              <Route exact path="/contact-us" component={ContactUs} />
              <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route exact path="/blog" component={Blog} />
              <Route exact path="/blog/:urlSlug" component={BlogPost} />

              <Route component={NotFound} />
            </Switch>
          </Layout>
        </ScrollTop>
      </Router>
    </>
  );
}

export default App;
