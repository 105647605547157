import React, { useState } from 'react';
import Row from './../Row/Row';
import saLogo from '../../../../Assets/Images/Training/UNLogos/saLogo.jpg';
import './SDGs.scss';
import LineIcon from 'react-lineicons';
import { sdgItems } from './sdgData';
import FsLightbox from 'fslightbox-react';
import RowText from '../RowText/RowText';

const SDGs = () => {
    const [toggler, setToggler] = useState(false);
    const [video, setVideo] = useState();

    const toggleVideo = (name) => {
        setVideo(name);
        setToggler(!toggler);
    }

    return (
        <Row>
            <div>
                <RowText
                    lineText="One world 17 goals"
                    title="What are the Sustainable Development Goals?"
                >
                    <p className="mt-4">In 2015 the United Nations General Assembly adopted the 17 Sustainable Development Goals (SDGs) as a framework for a sustainable future with the aim that they would all be successfully implemented by 2030. There is work to do to ensure we achieve these goals within the timeframe but they are a necessity if we are to create a sustainable future for generations to come.</p>
                    <p><strong>To learn more, click on each goal below and find out what you can do to play your part in this global ambition.</strong></p>
                </RowText>
                <div className="wrapper">
                    <div className="square-grid">
                        {sdgItems.map((s, i) => (
                            <div key={i} className="sdgcontainer">
                                <div className="sdgcontent">
                                    <div className="content-overlay"></div>
                                    <img className="content-image" src={s.image} alt="sdg" />
                                    <div className="content-details" onClick={() => toggleVideo(s.videoLink)} >
                                        <LineIcon name="play" alt={`Watch our video to learn about ${s.name}`} style={{ color: "white" }} />
                                    </div>
                                </div>
                            </div>
                        ))
                        }
                        <div className="sdgcontainer">
                            <div className="sdgcontent">
                                <img className="content-image" src={saLogo} alt="sdg" />
                            </div>
                        </div>
                    </div>
                    <FsLightbox
                        toggler={toggler}
                        sources={[
                            <div className="">
                                <video className="video fadeInDelay animated" style={{ width: "90%" }}
                                    src={video}
                                    controls
                                    playsInline
                                    type='video/mp4'
                                    autoPlay
                                />
                            </div>
                        ]}
                    />
                </div>
            </div>
        </Row >
    );
};

export default SDGs;