import React from 'react';
import './WhyUs.scss';
import LineIcon from 'react-lineicons';
import LineText from './../../Shared/Components/LineText/LineText';
import { Link } from 'react-router-dom';

const WhyUs = () => {
    return (
        <div className="row why-us-container no-gutter bgGrey" style={{ position: 'relative' }}>
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center mt-4">
                <LineText lineText="Help us, to help you, to save the World" center={true} />
                <h2>A More Sustainable World</h2>
                <p className="mt-3">Don't leave your team behind, help them to help your organisation become more sustainable</p>
            </div>
            <div className="why-us mx-sm--auto">
                <div className="icon-box">
                    <div className="icon-circle">
                        <LineIcon name="hand" alt="No Poverty" style={{ color: "white" }} />
                    </div>
                    <div className="why-text">
                        <h6>No Poverty</h6>
                        <p>Did you know?</p>
                        <p>689 million people live in extreme poverty, surviving on less than $1.90 a day</p>
                        <Link to="/our-courses">Read More <LineIcon name="arrow-right" alt="read more" /></Link>
                    </div>
                </div>
                <div className="icon-box">
                    <div className="icon-circle">
                        <LineIcon name="sun" alt="Combat Climate Change" style={{ color: "white" }} />
                    </div>
                    <div className="why-text">
                        <h6>Combat Climate Change</h6>
                        <p>Did you know?</p>
                        <p>Greenhouse gas emissions must begin falling by 7.6% each year starting in 2020?</p>
                        <Link to="/our-courses">Read More <LineIcon name="arrow-right" alt="read more" /></Link>
                    </div>
                </div>
                <div className="icon-box">
                    <div className="icon-circle">
                        <LineIcon name="drop" alt="Clean Water" style={{ color: "white" }} />
                    </div>
                    <div className="why-text">
                        <h6>Clean Water</h6>
                        <p>Did you know?</p>
                        <p>2.2billion people around the world still lack safely managed drinking water?</p>
                        <Link to="/our-courses">Read More <LineIcon name="arrow-right" alt="read more" /></Link>
                    </div>
                </div>
            </div >
        </div >
    );
};

export default WhyUs;