import React from 'react';
import './Testimonials.scss';
import Carousel from 'react-elastic-carousel';
import TextRow from './../TextRow/TextRow';

const Testimonials = ({ divClass, lineText, title, paragraph, data }) => {

    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2 },
        { width: 768, itemsToShow: 2 },
        { width: 1200, itemsToShow: 3 }
    ];

    return (

        <TextRow
            divClass={divClass}
            lineText={lineText}
            title={title}
            paragraph={paragraph}
        >
            <div className="mt-5">
                <Carousel breakPoints={breakPoints}
                    enableAutoPlay={false}
                    pagination={false}
                    itemPadding={[10]}
                >
                    {
                        data.map((t, i) => (
                            <div key={`${i}-testimonial`} className="testimonial-container">
                                <div key={i} className="row no-gutters ac-testimonials">
                                    <div className="col-12 text-center ac-content">
                                        <p className="card-text">{t.Testimonial}</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </Carousel>
            </div>
        </TextRow>
    );
};

export default Testimonials;