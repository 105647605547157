import React from 'react';
import './ImageRow.scss';
import { isMobile } from "react-device-detect";
import { FadeInOnScroll } from '../../Utilities/FadeInOnScroll';
import LineText from '../LineText/LineText';

const ImageRow = ({ imageMob, imgOpacity, title, lineText, paragraph, image, divClass, children, animate }) => {
    const [ref] = FadeInOnScroll();

    return (
        <div ref={animate ? ref : null} className={`row no-gutters image-row shadow ${divClass ? divClass : ""}`}>
            <div className="section-img" style={{ backgroundImage: isMobile ? `url(${imageMob})` : `url(${image})`, opacity: `${imgOpacity ? imgOpacity : '1'}` }}>
                <div className="container-ac container-xxl center-content">
                    <div className="row">
                        <div className="col-12 col-lg-10 offset-lg-1">
                            <LineText lineText={lineText} center={true} />
                            <h2 className="text-center">{title}</h2>
                            <hr style={{ borderTop: "2px solid white", textAlign: "center", margin: "auto", paddingTop: "2rem" }} />
                            <p className="text-center">{paragraph}</p>
                        </div>
                        <div className="col-12 pt-3">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImageRow;