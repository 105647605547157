import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { getBlogPostsAsync, getBlogPostsByTagAsync } from '../Shared/Utilities/BlogService';
import { Helmet } from "react-helmet";
import Hero from '../../Assets/Images/Schools/schools.jpg';
import HeroMob from '../../Assets/Images/Schools/schools-mob.jpg';
import Header from '../Shared/Components/Header/Header';
import BlogRow from '../Shared/Components/Blog/BlogRow';
import './Blog.scss';

const Blog = () => {

    const [blogPosts, setBlogPosts] = useState([]);
    const [tag, setTag] = useState("");
    const [tags, setTags] = useState([]);

    useEffect(() => {
        const getPosts = async () => {
            let response;
            if (tag === "") {
                response = await getBlogPostsAsync(1);
            }
            else {
                response = await getBlogPostsByTagAsync(1, tag);
            }
            setBlogPosts(response.blogPosts);
            setTags(response.tags);
        }

        getPosts();
    }, [tag])

    const selectTag = (tag, id) => {
        setTag(tag);
        Array.from(document.querySelectorAll('.tag.active')).forEach((el) => el.classList.remove('active'));
        document.getElementById(id).classList.add('active');
    }

    return (
        <>
            <Helmet>
                <html lang="en" />
                <title>The Sustainability Academy | Blog</title>
                <meta name="description" content="The Sustainability Academy Blog" />
            </Helmet>

            <Header
                imageHeight="200px"
                lineText="The Sustainability Academy"
                title="Blog"
                subtitle="Latest posts" />

            <div className="container-xxl">
                <div className="container-ac bloglist">
                    <div className="row no-gutters row-pad pb-5 d-flex align-content-center flex-wrap justify-content-center">
                        <div className="px-3">
                            <h5 id="all" className="tag active" onClick={() => { selectTag("", "all") }}>All</h5>
                        </div>
                        {tags.map((t, i) =>
                            <div key={i} className="px-3">
                                <h5 className="tag" id={t.value} onClick={() => { selectTag(t.value, t.value) }}>{t.value}</h5>
                            </div>
                        )}
                    </div>
                    <div className="blog-container">
                        {blogPosts.map((bp, i) =>
                            <BlogRow key={i} blogPost={bp} />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Blog;