export const TestimonialArray = [
    {
        Title: "No Poverty, impact on role",
        Testimonial: "I think that this workshop will allow me to consider that poverty is still a huge part of our world and that people really need access to jobs and to talk to them appropriately as they may not be from the safe walk of life as I am."
    },
    {
        Title: "No Poverty, Changes I will make",
        Testimonial: "After this workshop I will consider living more sustainably and not buying into fast fashion even though it is easy to get caught up in and I will try and use my voice more often to try and make a difference through supporting petitions and creating awareness."
    },
    {
        Title: "No Poverty, most useful fact",
        Testimonial: "1 in 10 people globally are considered to be in extreme poverty."
    },
    {
        Title: "Zero Hunger - Changes I will make",
        Testimonial: "After this workshop I will consider making small changes within my life such as reducing food wastage and shopping at local markets, as well as keeping well informed on hunger and food insecurity around the globe and more locally so I can try and help."
    },
    {
        Title: "Zero Hunger - what else did you enjoy?",
        Testimonial: "Really like the fact that these have been sharing the impact and implications of Covid-19 on the SDGS Really like the fact that these have been sharing the impact and implications of Covid-19 on the SDGs – keeps it relevant and up to date."
    },
    {
        Title: "Zero Hunger - What changes will you make?",
        Testimonial: "To be less wasteful with food."
    },
    {
        Title: "No Poverty - most useful fact",
        Testimonial: "I found it really interesting that it would have to be a decade of change from 2020-2030 if the SDG’s are going to be met and that there are a lot of alarming facts, such as how few children around the world are receiving proper education.’"
    },
    {
        Title: " Intro to SDGS - What changes will you consider?",
        Testimonial: "After this introduction, I felt it highlighted that individual actions within a business and my professional life, not only my personal life also have an impact. I would consider my impact at work such as aiming to be inclusive, encourage diversity and reduce discrimination."
    },
    {
        Title: "What else did you enjoy?",
        Testimonial: "I thought the introduction was interesting and the animations, short videos and text were creative and informative."
    },
    {
        Title: "Introduction to SDGs- What impact will this have on your role?",
        Testimonial: "Understanding and correlating the goals of my company and knowing their role in implementing the SDGs for a sustainable future."
    },
    {
        Title: "Zero Hunger - what changes will you consider?",
        Testimonial: "I will strive to reduce my own food wastage."
    },
    {
        Title: "No Poverty - what impact will this have on your role?",
        Testimonial: "I will take a closer interest in pieces on clean water leaks and repairs, as water hygiene is interlinked with eradicating poverty."
    },
    {
        Title: "Introduction to SDGs - Most useful fact",
        Testimonial: "The most useful fact I gained from this workshop was the amount of Sustainable Development Goals there are and how the UN are aiming for these.’"
    },
    {
        Title: "No Poverty - impact on your role",
        Testimonial: "The learning will make me work more passionately as I am proud to work for a company that is dedicated to SDGs and its impact on the people and the environment."
    },
    {
        Title: "No Poverty - changes I may consider",
        Testimonial: "The changes that I might consider in my own life is to be more organised in donating clothing and items and being more conscious of my food waste."
    },
    {
        Title: "Zero hunger - impact on my role",
        Testimonial: "The learning will make me support a company that is dedicated to SGDs and its impact on the people and the environment."
    },
    {
        Title: "Zero Hunger - changes I may consider",
        Testimonial: "The changes that I might consider in my own life is to donate food and drinks to food banks regularly."
    },
    {
        Title: "Introduction to SDGs",
        Testimonial: "SDG’s influence all aspects of life and the world not just agriculture and waste. They also focus on technology and education. SDG’s are like a global plan to for change in in every aspect of life and how we live it."
    },
    {
        Title: "Introduction to SDGs - what else did you enjoy?",
        Testimonial: "I think workshops that inform like this one has, provide the opportunity for understanding and with understanding comes change and growth"
    },
    {
        Title: "Introduction to SDGs - What changes will you consider?",
        Testimonial: "I think it will change several aspects of my life from just looking at the changes I could make to actively making the changes. One fact stood out in my mind and it stuck with me that going forward I need to realise the effect that I could have on the environment around me."
    }
]