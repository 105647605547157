import React from 'react';
import { Link } from 'react-router-dom';
import Button from './../../Shared/Components/Button/Button';
import './ContactFormInputs.scss';

const ContactFormInputs = ({ handleChange, handleCheck, handleSubmit, state, errors, warning }) => {
    return (
        <>
            {warning &&
                <div className="alert alert-danger text-center" role="alert">
                    {warning}
                </div>
            }
            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="form-group">
                        <input type="text" id="name" placeholder="Name" name="name" className={`ac-input ${errors.name && errors.name.length > 0 ? "error-border" : ""}`} onChange={handleChange} />
                        <small className="errorMessage">{errors.name}</small>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="form-group">
                        <input type="text" id="company" name="company" placeholder="Company" className={`ac-input ${errors.company && errors.company.length > 0 ? "error-border" : ""}`} onChange={handleChange} />
                        <small className="errorMessage">{errors.company}</small>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="form-group">
                        <input type="text" id="email" name="email" placeholder="Email" className={`ac-input ${errors.email && errors.email.length > 0 ? "error-border" : ""}`} onChange={handleChange} />
                        <small className="errorMessage">{errors.email}</small>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="form-group">
                        <input type="text" id="phoneNumber" name="phoneNumber" placeholder="Phone Number" className={`ac-input ${errors.phoneNumber && errors.phoneNumber.length > 0 ? "error-border" : ""}`} onChange={handleChange} />
                        <small className="errorMessage">{errors.phoneNumber}</small>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 message-input">
                    <div className="form-group pb-2">
                        <textarea type="text" id="enquiry" name="enquiry" placeholder="Your Enquiry..." className={`ac-input ${errors.enquiry && errors.enquiry.length > 0 ? "error-border" : ""}`} onChange={handleChange} />
                        <small className="errorMessage">{errors.enquiry}</small>
                    </div>
                </div>
                <div className="col-12 grey-box grey-box--bottom mb-3 text-center">
                    <div className="">
                        <input onChange={handleCheck} type="checkbox" checked={state.agreedTerms} id="agreedTerms" name="agreedTerms" />
                        <label className="form-check-label mr-3 ml-2">I accept The Sustainability Academy's <Link to='/privacy-policy'>privacy policy</Link> </label>
                    </div>
                    <small className="errorMessage">{errors.agreedTerms}</small>
                    <p className="privacy-text mb-2 mt-3">We value your privacy and we will only use your information in accordance with our privacy policy.</p>
                    <div className="mt-4">
                        <Button buttonText="Submit" click={handleSubmit} center={true} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactFormInputs;