import React from 'react';
import CourseBox from './CourseBox/CourseBox';
import Goal1 from '../../../Assets/Images/Training/Courses/sdg-intro.jpg'
import Goal2 from '../../../Assets/Images/Training/Courses/img-goal-2.jpg';
import Goal7 from '../../../Assets/Images/Training/Courses/img-goal-7.jpg';
import Button from '../../Shared/Components/Button/Button';
import { FadeInOnScroll } from '../../Shared/Utilities/FadeInOnScroll'
import LineText from './../../Shared/Components/LineText/LineText';

const FeaturedCourses = () => {
    const [ref] = FadeInOnScroll();

    return (
        <section className={`row no-gutters row-pad `}>
            <div className="container-xxl">
                <div className="container-ac">
                    <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center">
                        <LineText lineText="Programmes" center={true} />
                        <h2>Our Featured Programmes</h2>
                        <p className="mt-3">Diploma in SDGs</p>
                        <p>Simplifying the goals to help your organisation embed into the DNA of your operation and each individual to understand how they can make a difference.</p>
                        <p style={{ fontStyle: "italic" }}>Fully digitalised programme that can be accessed online from any location.</p>
                    </div>
                    <div className="row mt-5 pt-4">
                        <CourseBox img={Goal1}
                            title="Sustainable Development Goals"
                            paragraph="This course will provide an overview and insight for individuals to understand what the 17 United Nations Sustainable Development Goals are, who is involved, what the global commitment is and how this translates to organisations and individual responsibility."
                            length="60" />
                        <CourseBox img={Goal2} title="Zero Hunger"
                            paragraph="This programme will provide a full overview into the global challenge to end hunger, achieve food security and improved nutrition and promote sustainable agriculture and provide your colleague population with an insight into how they can individually and collectively support your organisation and their community to achieve this goal and understand the challenge of goal 2."
                            length="60" />
                        <CourseBox img={Goal7} title="Affordable &amp; Clean Energy"
                            paragraph="This programme will provide a full overview into the global challenge to ensure access to affordable, reliable, sustainable and modern energy for all and provide your colleague population with an insight into how they can individually and collectively support your organisation and their community to achieve this goal and understand the challenge of goal 7"
                            length="60" />
                        <div ref={ref} className="col text-center pt-5">
                            <Button divClass="text-center" center={true} buttonText="Browse All Courses" buttonUrl="/our-courses" />
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
};

export default FeaturedCourses;