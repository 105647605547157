import React from 'react';
import './Header.scss';
import { isMobile } from "react-device-detect";
import LineText from '../LineText/LineText';

const Header = ({ headerImageMob, headerImage, lineText, title, subtitle }) => {
    return (
        <header className="row no-gutters header shadow">
            <div className="overlay"></div>
            <div className="header-img" style={{ backgroundImage: isMobile ? `url(${headerImageMob})` : `url(${headerImage})` }}></div>
            <div className="container-xxl header-text">
                <LineText lineText={lineText} center={true} />
                <h1>{title}</h1>
                <p>{subtitle}</p>
            </div>
        </header>
    );
};

export default Header;