import React from 'react';
import Course from './../Course/Course';

const Courses = ({ coursesToRender }) => {
    return (
        <>
            {
                coursesToRender.map((c, i) => (
                    <Course key={i}
                        title={c.Title}
                        paragraph={c.Description}
                        courseLength={c.CourseLength}
                        img={c.Image}
                        overlay={c.Overlay}
                    />
                ))
            }
        </>
    );
};

export default Courses;