import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getBlogPostAsync } from '../Shared/Utilities/BlogService';
import { Link } from 'react-router-dom';
import './BlogPost.scss';
import parse from 'html-react-parser';
import Header from '../Shared/Components/Header/Header';
import BlogSidePosts from './BlogSidePosts';

const BlogPost = () => {
    const [blogPost, setBlogPost] = useState({ tags: [], attachments: [] });
    const { urlSlug } = useParams();
    const [body, setBody] = useState("");

    useEffect(() => {
        const getPost = async () => {
            let response = await getBlogPostAsync(urlSlug);
            setBlogPost(response);
            setBody(parse(response.body));
        }

        getPost();
    }, [urlSlug])

    return (
        <>
            <Header
                imageHeight="200px"
                lineText="The Sustainability Academy"
                title="Blog"
                subtitle="Latest posts" />

            <div className="container-xxl">
                <div className="container-ac">
                    <div className="row no gutters row-pad blog-post">
                        <div className="col-12 col-lg-8 ">
                            <div className="pb-3">
                                <h2>{blogPost.title}</h2>
                                {
                                    blogPost.tags.map((t, i) =>
                                        <Link className="tags" key={i} to={`/blog`}><span>{t.value}</span></Link>
                                    )
                                }
                            </div>
                            <img className="img img-fluid" src={blogPost.imageUrl} />

                            <div className="pt-3">
                                <p>{body}</p>
                            </div>
                            <p>Attachments:</p>
                            {
                                blogPost.attachments.map((a, i) =>
                                    <button key={i}>{a.title}</button>
                                )
                            }
                        </div>
                        <div className="d-none d-lg-block col-lg-4 pt-5">
                            <BlogSidePosts />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlogPost;