import React from 'react';
import './Course.scss';
import LineIcon from 'react-lineicons';
import { FadeInOnScroll } from '../../../Shared/Utilities/FadeInOnScroll';

const Course = ({ title, paragraph, courseLength, img, overlay }) => {
    const [ref] = FadeInOnScroll();

    return (
        <div ref={ref} className="course-item">
            {overlay &&
                <div className="overlay-desktop">
                    <img src={overlay} width="200px" height="200px" className="" alt="The Sustainability Academy Sustainable Development Goals" />
                </div>
            }
            <img src={img} className="img-fluid" alt={title} width="480px" height="370px" />
            <div className="row no-gutters course-info">
                {overlay &&
                    <div className="overlay-mobile">
                        <img src={overlay} width="200px" height="200px" className="" alt="" />
                    </div>
                }
                <div className="info-container">
                    <h5>{title}</h5>
                    <p>{paragraph}</p>
                    <div className="d-flex justify-content-end mt-auto">
                        <div className="text-right course-length">
                            <LineIcon name="timer" alt="SDG Course Length" style={{ color: "#079fd0", marginRight: "10px" }} />
                            <span className="pull-right">{courseLength}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Course;