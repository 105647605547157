import React from 'react';
import './PartnersRow.scss';
import { FadeInOnScroll } from '../../Shared/Utilities/FadeInOnScroll';
import LineText from '../../Shared/Components/LineText/LineText';
import Button from '../../Shared/Components/Button/Button';

const PartnersRow = ({ lineText, title, link, client, image, paragraph, divClass, animate, rowReverse, children }) => {
    const [ref] = FadeInOnScroll();

    return (
        <section className={`row no-gutters partners ${divClass != null ? divClass : ""}`}>
            <div className="container-xxl">
                <div ref={animate ? ref : null} className="container-ac">
                    <div className={`partners-image-container no-gutters  ${rowReverse ? "row-reverse" : ""}`}>
                        <div className={`col-12 col-md-7 ${rowReverse ? "reverse" : "pr-0 pr-md-5"}`}>
                            <LineText lineText={lineText} />
                            <div className="text mb-4">
                                <h2>{title}</h2>
                                <div className="pt-2">
                                    <p>{paragraph}</p>
                                </div>
                            </div>
                            <p className="client-quote" style={{ fontStyle: 'italic', fontWeight: 'bold', fontSize: '14px' }}>{client}</p>
                            {children}
                        </div>
                        <div className={`col-12 col-md-5 text-center image`}>
                            <a target="_blank" href={link}>
                                <img src={image} alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default PartnersRow;