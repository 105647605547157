import React from 'react';
import Hero from '../../Assets/Images/Schools/schools.jpg';
import HeroMob from '../../Assets/Images/Schools/schools-mob.jpg';
import Header from '../Shared/Components/Header/Header';
import Classroom from '../../Assets/Images/Schools/classroom.jpg';
import KidsSDGLogo from '../../Assets/Images/Schools/KidsSDGLogo.png';
import Students from '../../Assets/Images/Schools/students.jpg';
import Cosmo from '../../Assets/Images/Schools/cosmo.jpg';
import TextImageRow from '../Shared/Components/TextImageRow/TextImageRow';
import './Schools.scss';
import TextRow from './../Shared/Components/TextRow/TextRow';
import Video from '../Shared/Components/Video/Video';
import Animation from '../../Assets/Video/sdg-video.mp4';
import CosmoDK from '../../Assets/Images/Schools/CosmoDK.png';
import { Helmet } from "react-helmet";

const Schools = () => {
    return (
        <>
            <Helmet>
                <html lang="en" />
                <title>The Sustainability Academy | SDGs for Kids</title>
                <meta name="description" content="We must educate our future generation on how they can live more sustainable lives" />
            </Helmet>

            <Header
                img={Cosmo}
                imageHeight="200px"
                headerImage={Hero}
                headerImageMob={HeroMob}
                lineText="The next generation"
                title="KidsSDGs"
                subtitle="Our future decision makers" />

            <TextImageRow
                lineText="The Global Challenge"
                title="The next 10 years is crucial for our planet. Let's help our future generation to understand the challenge"
                paragraph1="The challenge to educate our future generation on how they can live more sustainable lives and contribute to saving our planet starts in our schools. We also want to share experiences in the classroom with our global community and encourage a 'ONE World' approach with a real cultural exchange of ideas encouraging our next generation of school children across the globe to adopt ethical practices as they move towards their career choices."
                image={KidsSDGLogo}
                divClass=""
                animate={true}
            >
                <a href="https://issuu.com/thesustainabilityacademy/docs/kidssdgs_brochure" target="_blank" className="ac-btn-full" style={{ width: "275px" }} role="button">
                    <div className="">
                        View Our KidsSDGs Brochure
                    </div>
                </a>
            </TextImageRow>

            <Video
                image={CosmoDK}
                video={Animation}
                animate={true}
                lineText="Coming Soon"
                title="Watch Out For Cosmo &amp; DK"
                paragraph="'The Adventures of Cosmo and DK' a journey through the SDGs for primary children, a range of story books for Children taking them through each SDG with Cosmo, DK and friends."
            />

            <TextRow
                lineText="You can make A Difference"
                title="KidsSDGs"
                paragraph="The Sustainability Academy are passionate about giving back and we will be challenging our clients to do the same by sponsoring a child or a school to complete the KidsSDGs programme, either in their local communities or from schools in less developed environments to complete the KidsSDGs programme."
                divClass="bgNavy"
                animate={true}
            />

            <TextImageRow
                lineText="With Schools in mind"
                title="The Sustainability Academy's KidsSDGs"
                paragraph1="The Sustainability Academy KidsSDGs Programme is designed to adapt to the needs of pupils across key stages 2 - 4, mapped to the national curriculum and is aligned to the 17 Sustainable Development Goals. It is designed to raise awareness of the key sustainability challenges we all face. "
                image={Classroom}
                divClass="bgGrey"
                animate={true}
            />

            <TextImageRow
                lineText="A global enrichment programme"
                title="Get your school involved in a cultural exchange and climate action"
                paragraph1="A fully comprehensive enrichment programme encouraging enterprise and entrepreneurship aligned to the SDGs where pupils will study each SDG as a module either online or in the classroom and be assessed at the end of each module.  There is an end-project based around building a business within their community which encompasses and is assessed against all 17 SDGs."
                image={Students}
                divClass=""
                animate={true}
                rowReverse={true}
            />
        </>
    );
};

export default Schools;