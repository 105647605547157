import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './BlogRow.scss';
import h2p from 'html2plaintext';

const BlogRow = ({ blogPost }) => {

    const [body, setBody] = useState("");

    useEffect(() => {
        setBody(h2p(blogPost.body).split(/\s+/).slice(0, 25).join(" ") + "...");

    }, [blogPost])

    return (
        <section className="blog-item flex-column">
            <Link to={`blog/${blogPost.urlSlug}`}>
                <div className="item-img pb-3">
                    <img src={blogPost.imageUrl} alt={blogPost.title} />
                </div>
                <div className="py-2">
                    <h3>{blogPost.title}</h3>
                </div>
                <div className="text mb-4">
                    <p>{body}</p>
                </div>
                {
                    blogPost.tags.map((t, i) =>
                        <span className="badge badge-info badge-pill tags" key={i}>{t.value} </span>
                    )
                }
            </Link>
        </section>
    );
};

export default BlogRow;