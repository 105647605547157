import axios from 'axios';
import { baseUrl } from './ApiConfig';

const CONTROLLER_NAME = "Blog";

export const getBlogPostsAsync = async (pageNumber) => {
    const response = await axios
        .get(`${baseUrl()}${CONTROLLER_NAME}/GetBlogPosts?pageNumber=${pageNumber}`);
    return response.data;
}

export const getBlogPostsByTagAsync = async (pageNumber, tag) => {
    const response = await axios
        .get(`${baseUrl()}${CONTROLLER_NAME}/GetBlogPostsByTag?pageNumber=${pageNumber}&tagName=${tag}`);
    return response.data;
}

export const getBlogPostAsync = async (urlSlug) => {
    const response = await axios
        .get(`${baseUrl()}${CONTROLLER_NAME}/GetBlogPost?urlSlug=${urlSlug}`);
    return response.data;
}