import React from 'react';
import LineText from './../LineText/LineText';
import { FadeInOnScroll } from '../../Utilities/FadeInOnScroll';

const TextRow = ({ title, lineText, paragraph, animate, divClass, children }) => {
    const [ref] = FadeInOnScroll();

    return (
        <section ref={animate ? ref : null} className={`row no-gutters row-pad ${divClass ? divClass : ""}`}>
            <div className="container-xxl">
                <div className="container-ac">
                    <div className="col-12 col-lg-10 offset-lg-1 text-center">
                        <LineText lineText={lineText} center={true} />
                        <h2>{title}</h2>
                        <hr style={{ borderTop: "2px solid white", textAlign: "center", margin: "auto", paddingTop: "2rem" }} />
                        {paragraph &&
                            <p style={{ fontSize: "17px" }}>{paragraph}</p>
                        }
                    </div>
                    <div className="col-12">
                        {children}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TextRow;