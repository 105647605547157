import React from 'react';
import './GetInTouch.scss';
import GetInTouchImage from '../../../../Assets/Images/GetInTouch/get-in-touch.jpg'
import ImageRow from './../ImageRow/ImageRow';
import Button from '../Button/Button';

const GetInTouch = () => {
    return (
        <ImageRow
            image={GetInTouchImage}
            imageMob={GetInTouchImage}
            divClass="get-in-touch"
            lineText=""
            title="Get In Touch"
            paragraph="Contact us and lets discuss how we can help your people understand the global challenge"
        >
            <div className="container-xxl container-ac get-in-touch-text">
                <div className="col-12 col-md-8 offset-md-2">
                    <div className="text-center pt-3 mx-auto">
                        <Button outline={false} center={true} buttonText="Contact Us" buttonUrl="/contact-us" />
                    </div>
                </div>
            </div>
        </ImageRow>
    );
};

export default GetInTouch;